import React from 'react'
import {
  TitleTextBlock,
} from '../../../../components'

const HeaderServices = ({
  data,
  t,
  title,
}) => (
  <TitleTextBlock
    textButton={data.link.title}
    link={data.link.url}
    widthImage={true}
    text={t('tagline.industries')}
    descr={data.copy}>
    {title}
  </TitleTextBlock>
)

export default HeaderServices
