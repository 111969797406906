import _ from 'lodash'
import React from 'react'

const ExperiencesIndustries = ({
  data,
  t,
}) => (
  <div className='row experiences__container'>
    <div className='col-12 col-md-4'>
      <h2>{t('industries.experiences.title')}</h2>
    </div>
    <div className='col-12 col-md-8'>

      <div className='row d-flex flex-column'>
        {
          _.map(data.list, (item, index) => (
            <div className='col-12  experiences__card__container' key={index}>
              <div className='experiences__card__box'>
                <div className='experiences__card__content'>
                  <h4>{_.get(item, 'title')}</h4>
                  <p>{_.get(item, 'copy')}</p>
                </div>
                <div
                  className='experiences__card__image'>
                  <img className='experiences__image' src={item.image.url ? item.image.url : undefined} alt="" />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>

  </div>
)

export default ExperiencesIndustries
